import axios from '@axios'
import qs from 'qs'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        update(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/user/update',params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
