<template>
    <b-row class="auth-inner m-1">
        <b-link class="brand-logo">
            <h2 class="brand-text text-primary ml-1">
                SBC
            </h2>
        </b-link>

        <b-col
                md="2"
        >
        </b-col>

        <b-col

                lg="8"
                class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
            <b-col
                    sm="8"
                    md="8"
                    lg="12"
                    class="px-xl-2 mx-auto"
            >
                <b-card-title
                        class="mb-1 font-weight-bold"
                        title-tag="h2"
                >
                    欢迎回来! 👋
                </b-card-title>
                <b-card-text class="mb-2">
                    请修改您的密码，确保账号安全
                </b-card-text>

                <b-alert
                        variant="primary"
                        show
                >
                    <div class="alert-body font-small-2">
                        <p>
                            <small class="mr-50"><span class="font-weight-bold">温馨提示:</span> 新密码由8~10位数字和字母组成</small>
                        </p>
                    </div>
                    <feather-icon
                            v-b-tooltip.hover.left="'如无法修改，请联系管理员'"
                            icon="HelpCircleIcon"
                            size="18"
                            class="position-absolute"
                            style="top: 10px; right: 10px;"
                    />
                </b-alert>

                <!--form-->
                <b-form
                        class="auth-login-form mt-2"
                        @submit.prevent="updatePassword"
                >
                    <!--旧密码-->
                    <b-form-group
                            label="旧密码"
                            label-for="password"
                            class="mb-1 required"
                    >
                        <b-form-input
                                id="password"
                                v-model="password"
                                name="password"
                                type="password"
                                placeholder="请输入旧密码"
                        >
                        </b-form-input>
                    </b-form-group>

                    <!--新密码-->
                    <b-form-group
                            label="新密码"
                            label-for="newpwd"
                            class="mb-1 required"
                    >
                        <b-form-input
                                id="newpwd"
                                v-model="newpwd"
                                name="newpwd"
                                type="password"
                        >
                        </b-form-input>
                    </b-form-group>

                    <!--确认密码-->
                    <b-form-group
                            label="确认新密码"
                            label-for="renewpwd"
                            class="mb-1 required"
                    >
                        <b-form-input
                                id="renewpwd"
                                v-model="renewpwd"
                                name="renewpwd"
                                type="password"
                        >
                        </b-form-input>
                    </b-form-group>

                    <!--submit buttons-->
                    <b-button
                            type="submit"
                            variant="primary"
                            block
                    >
                        保存
                    </b-button>
                </b-form>
            </b-col>
        </b-col>

        <b-col
                cols="12"
                md="2"
        >
        </b-col>
    </b-row>
</template>

<script>
    import {
        BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText,
        BCardTitle, BImg, BForm, BButton,
        BAlert, VBTooltip,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import {onUnmounted, ref} from '@vue/composition-api'
    import router from "@/router";
    import store from "@/store";
    import { initialAbility } from '@/libs/acl/config'
    import useToken from '@/auth/token/useToken'
    import pwdStore from './pwdStore'
    // Notification
    import { useToast } from 'vue-toastification/composition'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import { toTime, toDate, upperCase, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

    export default {
        directives: {
            'b-tooltip': VBTooltip,
        },
        components: {
            BRow,
            BCol,
            BLink,
            BFormGroup,
            BFormInput,
            BInputGroupAppend,
            BInputGroup,
            BFormCheckbox,
            BCardText,
            BCardTitle,
            BImg,
            BForm,
            BButton,
            BAlert,
            vSelect,
            useToast,
        },
        data() {
            return {
                    password:'',
                    newpwd:'',
                    renewpwd:'',
            }
        },

        methods: {
            updatePassword(){
                store.dispatch('pwd/update',{
                    password:this.password,
                    newpwd:this.newpwd,
                    renewpwd:this.renewpwd,
                }).then(res => {
                    if (res.data.code === 0) {
                        this.$toast.success("密码修改成功");
                        localStorage.removeItem(useToken.tokenConfig.storageTokenKeyName)
                        localStorage.removeItem(useToken.tokenConfig.storageRefreshTokenKeyName)

                        // Remove userData from localStorage
                        localStorage.removeItem('userData')

                        // Reset ability
                        this.$ability.update(initialAbility)

                        //return login page
                        this.$router.push({ name: 'auth-login' })

                    } else {
                        this.$toast.error(res.data.data)
                    }
                })
            },

        },
        setup() {
            // Register module
            if (!store.hasModule('pwd')) store.registerModule('pwd', pwdStore)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule('pwd')) store.unregisterModule('pwd')
            })


            return {
                upperCase,
                getCodeOptions,
                getCode,
                getCodeLabel,
                getCodeColor,
            }
        },
        created() {
        },
    }
</script>
<style>
    #resetForm{
        height: 100%;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
